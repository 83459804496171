<template>
  <div class="space-y-2">
    <label
      v-if="props.label"
      :class="{ 'text-red-500': errorMessage }"
      :for="`id_${props.name}`"
      class="block"
    >
      {{ props.label }}
    </label>

    <component
      v-bind="$attrs"
      :id="`id_${props.name}`"
      :is="fieldToComponent[props.as]"
      :modelValue="inputValue"
      :name="name"
      fluid
      @update:modelValue="onChange"
    />

    <FieldError :error="errorMessage" />
  </div>
</template>

<script lang="ts" setup>
import AutoComplete from 'primevue/autocomplete'
import Checkbox from 'primevue/checkbox'
import ColorPicker from 'primevue/colorpicker'
import DatePicker from 'primevue/datepicker'
import Editor from 'primevue/editor'
import InputNumber from 'primevue/inputnumber'
import InputText from 'primevue/inputtext'
import MultiSelect from 'primevue/multiselect'
import Password from 'primevue/password'
import Select from 'primevue/select'
import SelectButton from 'primevue/selectbutton'
import Slider from 'primevue/slider'
import Textarea from 'primevue/textarea'
import ToggleButton from 'primevue/togglebutton'
import ToggleSwitch from 'primevue/toggleswitch'
import RemoteAutocomplete from './fields/RemoteAutocomplete.vue'
import FieldObject from './fields/FieldObject.vue'
import ListInputText from './fields/ListInputText.vue'
import ListZipCodeRange from './fields/ListZipCodeRange.vue'

const fieldToComponent = {
  'AutoComplete': AutoComplete,
  'Checkbox': Checkbox,
  'ColorPicker': ColorPicker,
  'DatePicker': DatePicker,
  'Editor': Editor,
  'InputNumber': InputNumber,
  'InputText': InputText,
  'MultiSelect': MultiSelect,
  'Password': Password,
  'Select': Select,
  'SelectButton': SelectButton,
  'Slider': Slider,
  'Textarea': Textarea,
  'ToggleButton': ToggleButton,
  'ToggleSwitch': ToggleSwitch,
  // Custom Fields
  'FieldObject': FieldObject,
  'RemoteAutocomplete': RemoteAutocomplete,
  'ListInputText': ListInputText,
  'ListZipCodeRange': ListZipCodeRange
}

const emit = defineEmits<{
  change: [value: any]
}>()
const props = defineProps<{
  as: string | Component,
  label?: string,
  name: string,
  value?: any
}>()

const name = toRef(props, 'name')
const {
  value: inputValue,
  errorMessage,
  handleChange,
} = useField(name, undefined, {
  initialValue: props.value,
})

defineOptions({
  inheritAttrs: true,
})

const onChange = (evt: any) => {
  handleChange(evt)
  emit('change', evt)
}
</script>

